(function () {'use strict';}());
angular.module("app").directive('administratorView', function($document,$timeout,AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/administrator.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.generateReport = function() {
        	AppService.generateReport().then(onGenerateReport);
        };

        $scope.generateAssessmentReport = function() {
          AppService.generateAssessmentReport().then(onGenerateReport);
        };

        function onGenerateReport() {
        	
        }
      }  
    };
  });
(function () {'use strict';}());
var rtmapp = (function(self){

	angular.module('app', []).config(function($sceProvider){
		$sceProvider.enabled(false);
	});

	angular.GET = (function() { var vars = {}; var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) { vars[key] = value;}); return vars;}()); 

	angular.trace = function (){
		for(var i = 0, count = arguments.length; i < count; i++)
		{
		  try {console.log(arguments[i]);}
		  catch (error){}
		}
	};
	return self;
}(rtmapp = rtmapp || {}));
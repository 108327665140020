(function () {'use strict';}());
angular.module("app").directive('loginView', function($document,$timeout,$window) {
    return {
      restrict: 'A',
      templateUrl: 'views/login.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.userInfo = {};
        $scope.error = null;
        $scope.showLogin = false;

        $scope.showLoginView = function() {
          $scope.showLogin = true;
        };

        $scope.submit = function($event) {
          $event.preventDefault();
          $scope.login($scope.userInfo).then(onLogin);
        };

        $scope.signup = function($event) {
          $event.preventDefault();
          $scope.setCurrentPage($scope.PAGE_REGISTRATION);
        };

        $scope.forgotPassword = function($event) {
          $event.preventDefault();
          $event.stopImmediatePropagation();
          $scope.setCurrentPage($scope.PAGE_PASSWORD);
        };

        function onLogin(result) {
          if(result.data.error) {
            $scope.error = result.data.message;
            return;
          }
          $scope.error = null;
          $window.location.reload();
        }
      }  
    };
  });
(function () {'use strict';}());
angular.module("app").directive('resourcesView', function($document,$timeout,$window) {
    return {
      restrict: 'A',
      templateUrl: 'views/resources.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.openLink = function(url) {
        	$window.open(url,"_blank");
        };
      }  
    };
  });
(function () {'use strict';}());
angular.module("app").directive('lessonsView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/lessons.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.getStatusText = function(text) {
        	if(!text) return null;
        	if(text.match(/start/gim)) return "Start";
        	if(text.match(/completed/gim)) return "Completed";
        	return "In Progress";
        };
      }  
    };
  });
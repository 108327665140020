(function () {'use strict';}());
angular.module("app").directive('passwordView', function($document,$timeout,$window,AppService) {
    return {
      restrict: 'A',
      templateUrl: 'views/password.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.PASSWORD_EMAIL = 1;
        $scope.PASSWORD_CONFIRM = 2;
        $scope.PASSWORD_RESULT = 3;

        $scope.hash = angular.GET.hash;
        $scope.info = {email: null, password: null, confirm: null, hash: $scope.hash};

        initialize();

        $scope.email = function() {
        	AppService.emailResetLink($scope.info.email).then(onEmailSent);
        };

        $scope.cancel = function($event) {
        	$event.preventDefault();
        	$scope.setCurrentPage($scope.PAGE_LOGIN);
        };

        $scope.reset = function() {
        	AppService.resetPassword($scope.info.password).then(onPasswordReset);
        };

        function onEmailSent() {
        	$scope.currentView = $scope.PASSWORD_RESULT;
        }

        function onPasswordReset() {
        	$window.location.reload();
        }

        function initialize() {
	        if(angular.GET.reset === "true" && angular.GET.hash &&  __EXPIRED_LINK__ === false) {
	        	$scope.currentView = $scope.PASSWORD_CONFIRM;
	        } else {
	        	$scope.currentView = $scope.PASSWORD_EMAIL;
	        }        	
        }

      }  
    };
  });
angular.module('app').filter('modules', function() {
    var trace = angular.trace;
    return function(listing,excludeFirst) {
    	if(!listing) return listing;
    	var results = [];

        for(var i = 0, count = listing.length; i < count; i++) {
            if(excludeFirst === true && i === 0) continue;
            results.push(listing[i]);
        }

        return results;

    	
    };

    
});
(function () {'use strict';}());
angular.module("app").directive('headerView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/header.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $scope.isActive = function(value) {
        	if($scope.currentPage === value) return "active";
        	return null;
        };
      }  
    };
  });